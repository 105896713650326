<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #070911;
  &-container {
    width: 400px;
    height: 367px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: rgba(15, 17, 24, 1);
    font-size: 18px;
    font-family: "PingFangSC-Regular", "PingFang SC";
    font-weight: 400;
    color: rgba(85, 119, 224, 1);
    .el-input {
      margin-top: 16px;
    }
    &-button {
      width: 140px;
      height: 40px;
      margin: 0 auto;
      text-align: center;
      line-height: 40px;
      letter-spacing: 0;
      cursor: pointer;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 29px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
    p {
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      color: rgba(85, 119, 224, 1);
      line-height: 17px;
    }
    .sn-code {
      width: 340px;
      display: inline-flex;
      img {
        width: 40%;
        height: 40px;
        margin: 16px 0;
      }
    }
    &-title {
      font-size: 18px;
      font-weight: 400;
      color: rgba(85, 119, 224, 1);
      font-family: "PingFangSC-Regular", "PingFang SC";
      line-height: 25px;
      margin-top: 35px;
    }
    .forget-password {
      width: 85%;
      margin-left: 7.5%;
      margin-top: 0;
      text-align: right;
    }
    .which-channel {
      width: 85%;
      margin-left: 7.5%;
      font-size: 12px;
      font-weight: 400;
      color: rgba(222, 230, 255, 1);
      text-align: left;
    }
  }
  .missing-information {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(7, 9, 17, 0.8);
    z-index: 12;
    &-container {
      width: 460px;
      height: 240px;
      background: rgba(23, 25, 32, 1);
      border-radius: 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &-close {
        width: 18px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        margin: 0;
        text-align: center;
        &:nth-child(2) {
          margin: 30px 0;
        }
        &:nth-child(3) {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          padding: 0 23px;
        }
      }
    }
  }
  /deep/ .el-input__inner {
    background: rgba(15, 17, 24, 1);
    border: 1px solid rgba(222, 230, 255, 0.3);
    border-radius: 0px;
  }
  /deep/ .el-checkbox-group {
    width: 85%;
    margin-left: 7.5%;
    display: flex;
    flex-wrap: wrap;
    .el-checkbox {
      width: 40%;
      text-align: left;
      margin-top: 8px;
      margin-left: 2px;
    }
    .el-checkbox__inner {
      width: 16px;
      height: 16px;
      background: rgba(15, 17, 24, 1);
      border: 1px solid rgba(222, 230, 255, 0.3);
    }
    .el-checkbox__label {
      margin-left: 0;
    }
  }
}
</style>

<template>
  <div class="login">
    <div class="login-container"
         :style="{height: mode==='new' ? '463px': '367px'}">
      <div class="login-container-new"
           v-if="mode==='new'">
        <div class="login-container-title">
          {{ $t('createAccount') }}
        </div>
        <el-input v-model="email"
                  :placeholder="$t('enterEmail')"
                  :style="{width: '340px'}"></el-input>
        <el-input v-model="password"
                  :placeholder="$t('enterPassword')"
                  show-password
                  :style="{width: '340px'}"></el-input>
        <el-input v-model="confirmPassword"
                  :placeholder="$t('confirmPassword')"
                  show-password
                  :style="{width: '340px'}"></el-input>
        <p class="which-channel">
          {{ $t('whereToKnow') }}
        </p>
        <el-checkbox-group v-model="channel">
          <el-checkbox label="1">{{ $t('where1') }}</el-checkbox>
          <el-checkbox label="2">{{ $t('where2') }}</el-checkbox>
          <el-checkbox label="3">{{ $t('where3') }}</el-checkbox>
          <el-checkbox label="4">{{ $t('where4') }}</el-checkbox>
          <el-checkbox label="5">{{ $t('where5') }}</el-checkbox>
          <el-checkbox label="6">{{ $t('where6') }}</el-checkbox>
        </el-checkbox-group>
        <div class="login-container-button"
             @click="register"
             :style="{marginTop: '22px'}">
          {{ $t('register') }}
        </div>
        <p @click="mode='login'"> {{ $t('logInWithAnotherAccount') }}</p>
      </div>
      <div class="login-container-new"
           v-if="mode==='login'">
        <div class="login-container-title">
          {{ $t('welcome') }}
        </div>
        <el-input v-model="loginEmail"
                  :placeholder="$t('enterEmail')"
                  :style="{width: '340px'}"></el-input>
        <el-input v-model="loginPassword"
                  :placeholder="$t('enterPassword')"
                  show-password
                  :style="{width: '340px'}"></el-input>
        <div class="sn-code">
          <el-input v-model="loginSncode"
                    :placeholder="$t('enterCode')"
                    :style="{width: '240px'}"></el-input>
          <img :src="snCodeUrl"
               :title="$t('P17')"
               :alt="$t('P17')"
               @click="changeSnCodeUrl">
        </div>
        <p class="forget-password"
           @click="forgetPassword">{{ $t('forgetPassword') }}</p>
        <div class="login-container-button"
             @click="login">
          {{$t('login')}}
        </div>
        <p @click="mode='new'">{{$t('createAnAccount')}}</p>
      </div>
    </div>
  </div>
</template>

<script>

const tornodaBaseURL = '/api';

export default {
  name: 'login',
  data () {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      snCode: '',
      mode: 'login',
      codeUrl: '',
      loginEmail: '',
      loginPassword: '',
      loginSncode: '',
      channel: []
    }
  },
  mounted () {
    this.getCaptchaToken();
  },
  computed: {
    snCodeUrl () {
      if (this.codeUrl) {
        return tornodaBaseURL + '/captcha/' + this.codeUrl;
      } else {
        return '';
      }
    }
  },
  created () {
    this.codeUrl = tornodaBaseURL + '/captcha/' + this.codeUrl;
  },
  methods: {
    getCaptchaToken () {
      this.$api.getCaptcha_token().then(res => {
        this.codeUrl = res.token;
      })
    },
    register () {
      if (this.password.trim() === '' || this.email.trim() === '') {
        this.$message.error(this.$t('L25'));
        return false;
      }
      if (this.password.trim() !== this.confirmPassword.trim()) {
        this.$message.error('P13');
        return false;
      }
      /* eslint-disable */
      let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (!reg.test(this.email.trim())) {
        this.$message.error(this.$t('D48'));
        return false;
      }
      this.$api.postRegister({
        email: this.email.trim(),
        password: this.password.trim(),
        source: this.channel.map(item => parseInt(item)),
        token: '',
        captcha: ''
      }).then(() => {
        this.$message.success(this.$t('L26'));
        this.mode = 'login';
      }).catch(error => {
        this.$message.error(this.$t('L28'));
      })
    },
    login () {
      this.$api.postLogin({
        email: this.loginEmail,
        password: this.loginPassword,
        token: this.codeUrl,
        captcha: this.loginSncode
      }).then(res => {
        this.$store.commit('login', res);
        this.$message.success(this.$t('L27'));
        this.$Cookies.set('cecc-user', res, { expires: 7 });
        if (this.$route.params.redirect === 'fairviewJiangnan') {
          this.$router.push({
            name: 'building'
          });
        }
        // if (res.status === 2) {
        //   this.$router.push({
        //     name: 'personCenter'
        //   });
        // } else {
        if (this.$route.params.from) {
          this.$router.push({
            name: 'personCenter',
            params: {
              from: this.$route.params.from
            }
          });
        } else {
          this.$router.push({
            name: 'home'
          });
        }
        // }
      }).catch(error => {
        if (error.response.status === 403) {
          this.$message.error(this.$t('D47'));
        }
      })
    },
    changeSnCodeUrl () {
      this.getCaptchaToken()
    },
    forgetPassword () {
      this.$router.push({
        name: 'reset_password_sure'
      })
    }
  }
}
</script>
